// Build information, automatically generated by `ng-info`
const build = {
    version: "1.8.3",
    timestamp: "Mon Oct 07 2024 12:18:04 GMT+0100 (GMT+01:00)",
    message: null,
    git: {
        user: null,
        branch: "hotfix/signature-text",
        hash: "d5b4dc",
        fullHash: "d5b4dce22537ab529d37495c03a2107823e08e15"
    }
};

export default build;